// extracted by mini-css-extract-plugin
export var buttons = "esiaLoginDesk-module--buttons--0nhBl";
export var container = "esiaLoginDesk-module--container--QLyG+";
export var containerWithMore = "esiaLoginDesk-module--containerWithMore--QlwSa";
export var desc = "esiaLoginDesk-module--desc--obW88";
export var gosuslugiBtn = "esiaLoginDesk-module--gosuslugiBtn--QMCzd";
export var head = "esiaLoginDesk-module--head--Gww9X";
export var icon = "esiaLoginDesk-module--icon--KH4HM";
export var infoButton = "esiaLoginDesk-module--infoButton--cMr8f";
export var item = "esiaLoginDesk-module--item--1LqYl";
export var items = "esiaLoginDesk-module--items--3mXiR";
export var label = "esiaLoginDesk-module--label--fhNWx";
export var text = "esiaLoginDesk-module--text--OWUpP";
export var title = "esiaLoginDesk-module--title--3H+I2";