import React, { useEffect, useContext } from "react"

import type { ReactNode } from "react"

import { ProgressBarContext } from "../../../context"

type Props = {
  children: ReactNode
  name: string
  hide: boolean | string
}

/**
 * HOC для полей, которые сперва скрыты, но появляются после заполнения поля выше
 * Пример: поле localityAddress открывается только после заполнения поля region
 */

const HiddenField = ({ children, name, hide }: Props) => {
  const { setFieldsProgress } = useContext(ProgressBarContext)

  useEffect(() => {
    if (!hide) {
      setFieldsProgress((prev) => ({ ...prev, [`${name}`]: 0 }))
    }
  }, [hide, setFieldsProgress, name])

  return <div style={{ display: hide ? "block" : "none" }}>{children}</div>
}

export default HiddenField
