// extracted by mini-css-extract-plugin
export var bottomContainer = "DynamicProgressBar-module--bottomContainer--UKxZ5";
export var gradient = "DynamicProgressBar-module--gradient--rP-TL";
export var green = "DynamicProgressBar-module--green--5oQdI";
export var hidden = "DynamicProgressBar-module--hidden--rmnya";
export var mainContainer = "DynamicProgressBar-module--mainContainer--xrFea";
export var percent = "DynamicProgressBar-module--percent--LMRdj";
export var percentText = "DynamicProgressBar-module--percentText--oX85h";
export var position = "DynamicProgressBar-module--position--Qu93H";
export var progressBar = "DynamicProgressBar-module--progressBar---zCMv";
export var progressBarFill = "DynamicProgressBar-module--progressBarFill--GN57e";
export var stepInfo = "DynamicProgressBar-module--stepInfo--ZlrWq";
export var steps = "DynamicProgressBar-module--steps--M99ZV";
export var topContainer = "DynamicProgressBar-module--topContainer--qXKV3";