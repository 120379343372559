import React from "react"

import { ESIALoginMobile } from "./ESIALoginMobile"
import { ESIALoginDesk } from "./ESIALoginDesk"
import type { ESIALoginProps } from "./types"

export function ESIALogin({ disabled = false, onClick, deskClassName }: ESIALoginProps) {
  return (
    <>
      <ESIALoginMobile disabled={disabled} onClick={onClick} />
      <ESIALoginDesk disabled={disabled} onClick={onClick} deskClassName={deskClassName} />
    </>
  )
}
