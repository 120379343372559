import React, { useState } from "react"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import * as styles from "./esiaLoginMobile.module.scss"
import { GosuslugiIcon } from "../GosuslugiIcon"
import type { ESIALoginProps } from "../types"

const steps = [
  <>Войдите в Госуслуги и дайте разрешение на&nbsp;доступ к данным</>,
  <>Заявка заполнится автоматически, не&nbsp;потребуется никаких справок</>,
  <>
    Вероятность одобрения повысится <span className={styles.label}>+30%</span>
  </>,
  "Перед выдачей кредита мы дополнительно позвоним вам",
]

export function ESIALoginMobile({ disabled = false, onClick }: ESIALoginProps) {
  const [showInfo, setShowInfo] = useState(false)

  const onClickIfoButton = () => {
    if (!showInfo) {
      pushToDataLayer({
        event: "buttonClick",
        name: "more_details_esia",
        placement: "order",
      })
    }
    setShowInfo(!showInfo)
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <h4 className={styles.title}>Заполните через Госуслуги</h4>
          <p className={styles.subTitle}>Это надежно и безопасно</p>
        </div>

        <button className={styles.btn} onClick={onClick} disabled={disabled} type="button">
          <GosuslugiIcon className={styles.icon} />
          ВОЙТИ
        </button>
      </div>
      <div className={styles.steps}>
        <button className={styles.infoButton} onClick={onClickIfoButton} type="button">
          {showInfo ? "СВЕРНУТЬ" : "ПОДРОБНЕЕ"}
        </button>
        {showInfo ? (
          <ul className={styles.items}>
            {steps.map((step, i) => (
              <li className={styles.item} key={i}>
                {step}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  )
}
