import React, { useCallback, useEffect, useContext } from "react"
import clsx from "clsx"

import { ProgressBarContext } from "../../context"

import * as styles from "./DynamicProgressBar.module.scss"

type Props = {
  step: 1 | 2 | 3 | 4
  topText: string
  initProgress: number
  className?: string
  percentsForField?: Record<string, number>
  percentToGet: number
}

const DynamicProgressBar = ({
  step,
  topText,
  initProgress,
  className,
  percentsForField = {},
  percentToGet,
}: Props) => {
  const { fieldsProgress, overallProgress, setFieldsProgress, setOverallProgress } =
    useContext(ProgressBarContext)

  const fillBarListener = useCallback(
    (selector: string) => {
      const elems = document.querySelectorAll(selector)

      elems?.forEach((elem) => {
        elem.addEventListener("blur", (e) => {
          setTimeout(() => {
            const { value, ariaInvalid, name } = e.target as HTMLTextAreaElement | HTMLInputElement
            const isValid = value && ariaInvalid === "false"

            setFieldsProgress((prev) => ({ ...prev, [`${name}`]: +isValid }))
          }, 500)
        })
      })
    },
    [setFieldsProgress]
  )

  useEffect(() => {
    Object.keys(percentsForField).forEach((name: string) => {
      fillBarListener(`#app input[name=${name}]`)
      fillBarListener(`#app textarea[name=${name}]`)
    })
  }, [fillBarListener, percentsForField])

  useEffect(() => {
    let sum = initProgress

    /* eslint-disable-next-line no-restricted-syntax */
    for (const [key, value] of Object.entries(fieldsProgress)) {
      if (value === 0) continue

      sum += percentsForField[key]
    }

    setOverallProgress(sum)
  }, [fieldsProgress, initProgress, setOverallProgress, percentsForField])

  return (
    <div className={clsx(styles.mainContainer, className)}>
      <div className={styles.topContainer}>
        <div className={styles.percentText}>
          Получите
          <span className={styles.green}>{` +${percentToGet}% к одобрению `}</span>
          {topText}
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.progressBar}>
          <div
            className={styles.progressBarFill}
            style={{ width: `${overallProgress}%`, animationDuration: `${overallProgress + 10}s` }}
          />
        </div>
      </div>
      <div className={styles.stepInfo}>
        <span className={styles.steps}>{`Шаг ${step} из 4`}</span>
        <span>
          Заполните все данные
          <span className={styles.percent}>{` +${percentToGet}%`}</span>
        </span>
      </div>
    </div>
  )
}

export default DynamicProgressBar
