import React, { useState } from "react"

import type { ReactNode } from "react"

import { ProgressBarContext } from "../../../context"

type Props = {
  children: ReactNode
}

/**
 * Провайдер для контекста. Нужен на форме, куда подключается прогресс бар, для отслеживания полей
 */

const ProgressBarProvider = ({ children }: Props) => {
  const [overallProgress, setOverallProgress] = useState(0)
  const [fieldsProgress, setFieldsProgress] = useState<Record<string, number>>({})

  const onInputChange = (event: React.SyntheticEvent, fieldName: string) => {
    // Если произошло событие, значит, value было изменено вручную
    // Иначе value подставилось автозаполением от другого поля
    if (event) return

    setFieldsProgress((prev) => ({ ...prev, [`${fieldName}`]: 1 }))
  }

  const contextVal = {
    overallProgress,
    fieldsProgress,
    setFieldsProgress,
    setOverallProgress,
    onInputChange,
  }

  return <ProgressBarContext.Provider value={contextVal}>{children}</ProgressBarContext.Provider>
}

export default ProgressBarProvider
