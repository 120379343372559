import React, { useState } from "react"
import clsx from "clsx"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import * as styles from "./esiaLoginDesk.module.scss"
import { GosuslugiIcon } from "../GosuslugiIcon"
import type { ESIALoginProps } from "../types"

const steps = [
  <>&#8226; Войдите в Госуслуги и дайте разрешение на&nbsp;доступ к данным</>,
  <>&#8226; Заявка заполнится автоматически, не&nbsp;потребуется никаких справок</>,
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <div style={{ width: "160px" }}>&#8226; Вероятность одобрения&nbsp;повысится</div>
    <div className={styles.label}>+30%</div>
  </div>,
  <>&#8226; Перед выдачей кредита мы дополнительно позвоним вам</>,
]

export function ESIALoginDesk({ disabled = false, onClick, deskClassName }: ESIALoginProps) {
  const [showInfo, setShowInfo] = useState(false)

  const onClickIfoButton = () => {
    if (!showInfo) {
      pushToDataLayer({
        event: "buttonClick",
        name: "more_details_esia",
        placement: "order",
      })
    }
    setShowInfo(!showInfo)
  }

  return (
    <div className={clsx(styles.container, showInfo && styles.containerWithMore, deskClassName)}>
      <div className={styles.head}>
        <div className={styles.text}>
          <div className={styles.title}>
            Заполните
            <br />
            через Госуслуги
          </div>
          <div className={styles.desc}>Это надёжно и безопасно</div>
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.gosuslugiBtn}
            onClick={onClick}
            disabled={disabled}
            type="button"
          >
            <GosuslugiIcon className={styles.icon} />
            ВОЙТИ
          </button>
          <button className={styles.infoButton} onClick={onClickIfoButton} type="button">
            {showInfo ? "СВЕРНУТЬ" : "ПОДРОБНЕЕ"}
          </button>
        </div>
      </div>
      {showInfo ? (
        <ul className={styles.items}>
          {steps.map((step, i) => (
            <li className={styles.item} key={i}>
              {step}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}
