import requiredValidator from "@ecom/ui/components/FormLanding/validators/required"
import { emailValidator } from "@ecom/ui/components/FormLanding/validators/emailValidator"

import type { IDadataAddress } from "../interfaces/dadata"

export function noValidate() {
  return ""
}

type ValidationType = {
  region: {
    validated: boolean
    isValid: boolean
  }
  address: {
    validated: boolean
    isValid: boolean
  }
}

export function validateAddressWithIcons(dadataAddress: IDadataAddress, setValidation: any) {
  const { dadataValue } = dadataAddress

  const setInvalid = () =>
    setValidation((prev: ValidationType) => ({
      ...prev,
      address: {
        validated: true,
        isValid: false,
      },
    }))

  if (requiredValidator(dadataValue) || !dadataValue || !dadataValue.data) {
    setInvalid()
    return "Выберите значение из списка"
  }

  if (!dadataValue || !dadataValue.data) {
    return "Выберите значение из списка"
  }

  const {
    data: { city, settlement },
  } = dadataValue

  if (!(city || settlement)) {
    setInvalid()
    return "Укажите город или населенный пункт"
  }

  setValidation((prev: ValidationType) => ({
    ...prev,
    address: {
      validated: true,
      isValid: true,
    },
  }))

  return null
}

export function validateDadataAddressWithoutHouse(dadataAddress: IDadataAddress) {
  const { dadataValue, dadataWork, inputValue } = dadataAddress

  const regex = /^[а-яА-Я0-9\s]+(?:[а-яА-Я0-9]+(?:-| )[а-яА-Я0-9]+)*$/

  if (!dadataWork) {
    if (regex.test(inputValue)) {
      return null
    }

    return "Введите корректное значение"
  }

  if (requiredValidator(dadataValue)) {
    return "Выберите значение из списка"
  }

  if (!dadataValue || !dadataValue.data) {
    return "Выберите значение из списка"
  }

  const {
    data: { city, settlement },
  } = dadataValue

  if (!(city || settlement)) {
    return "Укажите город или населенный пункт"
  }

  return null
}

export function validateRegion(data: any, setValidation: any) {
  if (!(data && data.value)) {
    setValidation((prev: ValidationType) => ({
      ...prev,
      region: {
        validated: true,
        isValid: false,
      },
    }))
    return "Выберите значение из списка"
  }

  setValidation((prev: ValidationType) => ({
    ...prev,
    region: {
      validated: true,
      isValid: true,
    },
  }))

  return null
}

export function comparePhoneNumbers(phone1: string) {
  return (phone2: string) => {
    const reqMsg = requiredValidator(phone2)
    if (reqMsg) {
      return reqMsg
    }

    // eslint-disable-next-line react/destructuring-assignment
    if (phone1 && phone1.slice(1) === phone2.slice(1)) {
      return "Номер телефона должен отличаться от введённого ранее"
    }

    return null
  }
}

export function validateEmail(email: string) {
  if (!email.length) {
    return null
  }

  return emailValidator(email)
}
