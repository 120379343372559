import { createContext } from "react"

interface Value {
  loanAmount?: number
  term?: number
}

type FieldsProgressState = Record<string, number>

type SetFieldsProgress = (callback: (val: FieldsProgressState) => FieldsProgressState) => void

interface ProgressBarValue {
  overallProgress: number
  fieldsProgress: Record<string, number>
  setFieldsProgress: SetFieldsProgress
  setOverallProgress: (val: number) => void
  onInputChange: (event: React.SyntheticEvent, fieldName: string) => void
}

export const CalcWithFormContext = createContext<Value>({})
export const ProgressBarContext = createContext<ProgressBarValue>({
  overallProgress: 0,
  fieldsProgress: {},
  setFieldsProgress: () => {},
  setOverallProgress: () => {},
  onInputChange: () => {},
})
