import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"
import formatToRequest from "@ecom/ui/helpers/formatToRequest"
import canUseDom from "@ecom/ui/utils/canUseDom"

// import getAddress from "../../helpers/getAddress"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import type { ESIAData } from "../../interfaces/esia"
import type { PersonalInfoFormData, PersonalInfoFormDataFormatted, FieldsRecord } from "./types"

export const DEFAULT_LABELS: FieldsRecord<string> = {
  fio: "Фамилия Имя Отчество",
  birthDate: "Дата рождения",
  phone: "Телефон",
  region: "Регион",
  localityAddress: "Населенный пункт",
  email: "Email",
}

export const DEFAULT_FIELDS: FieldsRecord<boolean> = {
  fio: true,
  birthDate: true,
  phone: true,
  region: true,
  localityAddress: true,
  email: false,
}

export function checkUrl() {
  if (!canUseDom) {
    return false
  }

  const url = new URL(window.location.href)
  const host = "gethalva.ru"
  const utmMedium = url.searchParams.get("utm_medium")
  const utmMediumValues = ["SalesNetwork", "Network", "Representative", "Callcenter"]

  return (
    (process.env.NODE_ENV === "test" || url.host === host) &&
    utmMediumValues.includes(utmMedium as string)
  )
}

const fieldsType = {
  fio: "fio",
  birthDate: "date",
  region: "region",
  localityAddress: "dadataAddress",
  email: "email",
}

export function formatValuesToRequest(
  values: PersonalInfoFormData,
  productName: string = "КК Халва"
): PersonalInfoFormDataFormatted {
  const valuesToRequest = formatToRequest(values, fieldsType)

  if (valuesToRequest?.customTextField) {
    valuesToRequest.inviteFriendFromWhom = valuesToRequest?.customTextField
  }

  const { surname, name, patronymic } = valuesToRequest.fio

  // valuesToRequest.localityAddress = getAddress(valuesToRequest.localityAddress)
  valuesToRequest.additionalData = getAdditionalData()
  delete valuesToRequest.fio
  delete valuesToRequest.customTextField

  if (!valuesToRequest.email) {
    delete valuesToRequest.email
  }

  return { productName, surname, name, patronymic, ...valuesToRequest }
}

export function fetchESIAUrl(productName: string = "") {
  const redirectUrl = encodeURIComponent(window.location.origin + window.location.pathname)
  const pn = encodeURIComponent(productName)

  return fetch(`${process.env.GATSBY_APP_ESIA_API_URL}/?redirect=${redirectUrl}&pn=${pn}`, {
    credentials: "include",
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(`HTTP error. Status code: ${res.status}`)
      }

      return res.json()
    })
    .then(({ url }) => url)
    .catch(() => {
      pushToDataLayer({
        event: "Esia",
        name: "ReturnFromEsia",
        result: "error",
        placement: "order",
      })
    })
}

export function fetchESIAData(token: string): Promise<{ apiId: string; esiaInfo: ESIAData }> {
  const apiUrl = `${process.env.GATSBY_APP_ESIA_API_URL}/tokeninfo?token=${token}`

  return fetch(apiUrl, { credentials: "include" }).then((res) => {
    if (!res.ok) {
      throw Error(`HTTP error. Status code: ${res.status}`)
    }

    return res.json()
  })
}

// Для halva-auracard, halva-special, halva-planetacard
export const additionalGeoData: Record<string, any> = {
  "г Санкт-Петербург": {
    geo_lat: "59.8199093",
    geo_lon: "30.3169822",
    kladr_id: "7800000000000",
    offices: [
      {
        id: "04-08-05-072",
        name: "ТРК Лето",
        latitude: 59.8199093,
        longitude: 30.3169822,
        address: "г. Санкт-Петербург, Пулковское шоссе 25 корпус 1, лит. А",
        mode: {
          forIndividuals: ["с 10:00 до 22:00"],
        },
      },
    ],
  },
  "г Новосибирск": {
    geo_lat: "55.0302549",
    geo_lon: "82.9332885",
    kladr_id: "5400000100000",
    offices: [
      {
        id: "04-05-09-116",
        name: "УРМ ТЦ Аура",
        latitude: 55.0302549,
        longitude: 82.9332885,
        address: "г. Новосибирск, ул. Военная, д. 5",
        mode: { forIndividuals: ["с 10:00 до 22:00"] },
      },
    ],
  },
  "г Уфа": {
    geo_lat: "54.7577387",
    geo_lon: "56.0343011",
    kladr_id: "0200000100000",
    offices: [
      {
        id: "04-06-46-202",
        name: "УРМ ТЦ Планета",
        latitude: 54.7577387,
        longitude: 56.0343011,
        address: "г. Уфа, ул. Энтузиастов, д. 20",
        mode: { forIndividuals: ["с 10:00 до 22:00"] },
      },
    ],
  },
  "г Новокузнецк": {
    geo_lat: "53.780234",
    geo_lon: "87.1237493",
    kladr_id: "4200001200000",
    offices: [
      {
        id: "04-04-51-018",
        name: "УРМ ТЦ Планета",
        latitude: 53.780234,
        longitude: 87.1237493,
        address: "г. Новокузнецк, ул. Доз, д. 10А",
        mode: { forIndividuals: ["с 10:00 до 22:00"] },
      },
    ],
  },
  "г Красноярск": {
    geo_lat: "56.0511807",
    geo_lon: "92.9021067",
    kladr_id: "2400000100000",
    offices: [
      {
        id: "04-04-08-127",
        name: "УРМ ТЦ Планета",
        latitude: 56.0511807,
        longitude: 92.9021067,
        address: "г. Красноярск, ул. 9 Мая, д. 77",
        mode: { forIndividuals: ["с 10:00 до 22:00"] },
      },
    ],
  },
}
