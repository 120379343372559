import React, { useState, useEffect, useRef, useCallback, FC, useContext } from "react"

import { navigate } from "gatsby"

import requiredValidator from "@ecom/ui/components/FormLanding/validators/required"
import Button from "@material-ui/core/Button"
import Container from "@ecom/ui/components/Container"
import FormLanding from "@ecom/ui/components/FormLanding"
import DadataFio from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataFio"
import DadataAddress from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataAddress"
import EmailField from "@ecom/ui/components/FormLanding/fields/EmailField"
import DateField from "@ecom/ui/components/FormLanding/fields/DateField"
import TextField from "@ecom/ui/components/FormLanding/fields/TextField"
import RadioGroupSpecial from "@ecom/ui/components/FormLanding/fields/RadioGroupSpecial"
import AutocompleteRegionByPhoneField from "@ecom/ui/components/FormLanding/fields/AutocompleteRegionByPhoneField"
import PhoneFieldWithSeven from "@ecom/ui/components/FormLanding/fields/PhoneFieldWithSeven"
import AcceptmentField from "@ecom/ui/components/FormLanding/fields/AcceptmentField"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { pushToDataLayerOnce } from "@ecom/ui/utils/pushToDataLayerUtils"
import getKladrIds from "@ecom/ui/helpers/getKladrIds"
import getSearchParam from "@ecom/ui/utils/getSearchParam"
import { validateDadataAddressWithoutHouse, validateEmail } from "../../helpers/validators"
import { DynamicProgressBar, HiddenField } from "../DynamicProgressBar"

import FormControl from "../FormControl"

import {
  DEFAULT_LABELS,
  DEFAULT_FIELDS,
  checkUrl,
  formatValuesToRequest,
  fetchESIAData,
  fetchESIAUrl,
  additionalGeoData,
} from "./helpers"
import { getIDBValue, setIDBValue, setManyIDBValues } from "../../utils/idbUtils"
import disableWebvisor from "../../utils/disableWebvisor"

import type { FieldData } from "../../interfaces/fieldData"
import type { ESIAData } from "../../interfaces/esia"
import type { RegionData, PersonalInfoFormData } from "./types"

import * as styles from "./pif.module.scss"
import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"
import { ESIALogin } from "../ESIALogin"
import { ProgressBarContext } from "../../context"
import { handleClickForm } from "../../helpers/WatcherDL/utils/handleClickForm"

const PERCENTS_FOR_FIELDS = { fio: 4, birthDate: 4, phone: 4, region: 4, localityAddress: 4 }

const LOCALITY_DADATA_OPTIONS = {
  count: 10,
  from_bound: { value: "city" },
  restrict_value: true,
  to_bound: { value: "settlement" },
}
const EMPTY_REGION_VALUE = { label: "", value: "", kladr_id: "" }

type Region = {
  kladr_id: string
  value: string
  label: string
}

type ItemsT = {
  value: string
  label: string | React.ReactElement
}

export type PersonalInfoFormProps = {
  productName?: string
  title?: React.ReactNode
  subtitle?: React.ReactNode
  submitButtonText?: string
  hasESIA?: boolean
  onSubmit?: (v: Record<string, any>) => void
  labels?: typeof DEFAULT_LABELS
  ESIALoginComponent?: FC<any>
  fieldsAB?: boolean
  name?: string
  fields?: typeof DEFAULT_FIELDS
  regionOptions?: Region[]
  additionalValuesToRequest?: any
  orderNum?: string
  frame?: boolean
  radioButtons?: boolean
  hasProgressBar?: boolean
}

export function PersonalInfoForm({
  productName,
  title = "Получить Халву",
  subtitle,
  submitButtonText = "Заказать карту",
  hasESIA = false,
  onSubmit,
  ESIALoginComponent = ESIALogin,
  fieldsAB = false,
  name,
  labels,
  fields,
  regionOptions,
  additionalValuesToRequest,
  orderNum,
  frame = false,
  radioButtons = false,
  hasProgressBar,
}: PersonalInfoFormProps) {
  const fieldsLabel = { ...DEFAULT_LABELS, ...labels }
  const displayedFields = { ...DEFAULT_FIELDS, ...fields }
  const frameRef = useRef<HTMLDivElement>(null)
  const refForm = useRef<any>(null)
  const refPhone = useRef<HTMLInputElement>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [utmTags, setUtmTags] = useState(false)
  const [items, setItems] = useState<ItemsT[]>([
    { value: "Рассказали коллеги", label: "Рассказали коллеги" },
    { value: "От родственников / родителей", label: "От родственников / родителей" },
    { value: "От друзей / знакомых", label: "От друзей / знакомых" },
    { value: "Другое", label: "Другое" },
  ])

  const [esiaDisabledButton, setESIADisabledButton] = useState(false)
  const [esiaData, setEsiaData] = useState<Partial<ESIAData>>({})

  const { surname, name: userName, patronymic, birthDate, phone, email } = esiaData

  const isESIAShow = Object.keys(esiaData).length === 0
  const fioDefaultValue = [surname, userName, patronymic].filter((item) => Boolean(item)).join(" ")
  const [userPhone, setUserPhone] = useState<string>()
  const [region, setRegion] = useState<RegionData>(EMPTY_REGION_VALUE)
  const dadataOptions = {
    ...LOCALITY_DADATA_OPTIONS,
    locations: getKladrIds(region.kladr_id),
  }
  const combineSetEsiaDataAndPushStartFilling = (data: ESIAData) => {
    setEsiaData(data)
    pushToDataLayerOnce("Start_filling", {
      event: "Start_filling",
    })
  }
  const { onInputChange } = useContext(ProgressBarContext)

  const onChangeFields = {
    birthDate: ({ value }: FieldData<string>) => {
      if (fieldsAB && value.length === 10) {
        refPhone?.current?.focus()
      }
    },
    phone: ({ value }: FieldData<string>) => {
      setUserPhone(value)
    },
    inviteFriendFromWhom: ({ value }: FieldData<string>) => {
      if (value === "Другое") {
        setItems([
          { value: "Рассказали коллеги", label: "Рассказали коллеги" },
          { value: "От родственников / родителей", label: "От родственников / родителей" },
          { value: "От друзей / знакомых", label: "От друзей / знакомых" },
          {
            value: "Другое",
            label: (
              <div className={styles.customTextField}>
                <TextField
                  variant="standard"
                  placeholder="Введите свой вариант ответа"
                  name="customTextField"
                  validate={requiredValidator}
                />
              </div>
            ),
          },
        ])
      } else {
        setItems([
          { value: "Рассказали коллеги", label: "Рассказали коллеги" },
          { value: "От родственников / родителей", label: "От родственников / родителей" },
          { value: "От друзей / знакомых", label: "От друзей / знакомых" },
          { value: "Другое", label: "Другое" },
        ])
        refForm.current.setField("customTextField", {
          value: "",
          isRequired: false,
        })
      }
    },

    region: ({ value }: FieldData<RegionData>) => {
      if (!value) {
        refForm.current.setField("localityAddress", { value: "" })
        setRegion(EMPTY_REGION_VALUE)
        return
      }

      const newRegionValue = value
      const prevRegionValue = region

      setRegion(newRegionValue)
      if (!newRegionValue.value || newRegionValue.kladr_id !== prevRegionValue.kladr_id) {
        refForm.current.setField("localityAddress", { value: "" })
      }
    },
  }
  useEffect(() => {
    if (email && email.length > 0) refForm?.current?.setField("email", { value: email })
  }, [email])

  // отключение записи ПД в вебвизоре
  const ref = useCallback((node: any) => {
    disableWebvisor(node)
  }, [])

  // Для Frame
  useEffect(() => {
    if (frame) {
      // Отправить postMessage при инициализаций
      const initialWidth = frameRef.current?.offsetWidth
      const initialHeigth = frameRef.current?.offsetHeight
      const initialMessage = JSON.stringify({
        action: "RESIZE",
        width: `${initialWidth}px`,
        height: `${initialHeigth}px`,
      })
      window.parent.postMessage(initialMessage, "*")

      // Функция для отправки postMessage при изменений размера
      const handleResize = () => {
        const width = frameRef.current?.offsetWidth
        const height = frameRef.current?.offsetHeight
        const resizeMessage = JSON.stringify({
          action: "RESIZE",
          width: `${width}px`,
          height: `${height}px`,
        })
        window.parent.postMessage(resizeMessage, "*")
      }

      window.addEventListener("resize", handleResize)

      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }

    return () => {}
  }, [frame])

  useEffect(() => {
    if (
      getSearchParam("utm_medium") === "POS" &&
      getSearchParam("utm_source") === "mdn" &&
      getSearchParam("utm_campaign") === "liga2023" &&
      getSearchParam("utm_content") === "offline/"
    ) {
      setUtmTags(true)
    } else {
      setUtmTags(false)
    }
  }, [esiaData])

  useEffect(() => {
    if (!hasESIA && !utmTags) return

    const esiaToken = getSearchParam("token")

    if (esiaToken) {
      window.history.pushState(null, "", window.location.pathname)
      pushToDataLayer({
        event: "Esia",
        name: "ReturnFromEsia",
        result: "success",
        placement: "order",
      })
      // если есть token значит данные из esia уже ушли в /v2/app/start/
      // поэтому вместе с esiaData должен прийти apiId
      // запрос в start был сделан поэтому переводим в passport
      fetchESIAData(esiaToken).then(({ apiId, esiaInfo }) => {
        if (esiaInfo) {
          scrollToPersonalForm()
          combineSetEsiaDataAndPushStartFilling(esiaInfo)
          setManyIDBValues([
            ["id", apiId],
            ["esiaData", esiaInfo],
          ])
        }
      })
    }
  }, [hasESIA, utmTags])

  useEffect(() => {
    if (!hasESIA) return

    getIDBValue<ESIAData>("esiaData").then((data) => {
      if (data) {
        combineSetEsiaDataAndPushStartFilling(data)
      }
    })
  }, [hasESIA])

  function handleESIAClick() {
    pushToDataLayer({
      event: "buttonClick",
      name: "enter_esia",
      placement: "order",
    })

    setESIADisabledButton(true)
    fetchESIAUrl(productName).then((ESIAUrl) => {
      window.location = ESIAUrl
    })
  }

  function fillNewForm() {
    setIDBValue("id", null)
    navigate("/")
  }

  function handleSubmit(values: PersonalInfoFormData) {
    pushToDataLayer({ event: "buttonClick", name: submitButtonText, placement: "appl_halva" })
    setIsSubmitting(true)
    const valuesToRequest = formatValuesToRequest(values, productName)

    if (!displayedFields.region || !displayedFields.localityAddress) {
      const geoData = additionalGeoData[additionalValuesToRequest?.region || valuesToRequest.region]

      setManyIDBValues([
        ["name", valuesToRequest.name],
        ["birthDate", valuesToRequest.birthDate],
        ["phone", valuesToRequest.phone],
        ["geoData", geoData],
      ])

      const additionaData: any = {}
      if (valuesToRequest.region) {
        additionaData.localityAddress = valuesToRequest.region
      }

      if (onSubmit) {
        onSubmit({
          ...valuesToRequest,
          ...additionalValuesToRequest,
          ...additionaData,
        })
        return
      }
      return
    }

    function getGeoData() {
      if (values?.localityAddress?.dadataWork) {
        const { geo_lat, geo_lon, kladr_id } = values?.localityAddress?.dadataValue?.data
        const geoData = { geo_lat, geo_lon, kladr_id }

        return geoData
      }
      valuesToRequest.localityAddress = { value: values?.localityAddress?.inputValue }

      return null
    }

    setManyIDBValues([
      ["name", valuesToRequest.name],
      ["birthDate", valuesToRequest.birthDate],
      ["phone", valuesToRequest.phone],
      ["geoData", getGeoData()],
    ])

    if (onSubmit) {
      onSubmit(valuesToRequest)
    }
  }

  return (
    <section
      data-testid="form"
      id="app"
      className="content-visibility-auto"
      data-exclude={orderNum}
      ref={frameRef}
    >
      <Container className={styles.root}>
        <div className={styles.headContainer}>
          {title && (
            <h2 className={styles.head} ref={ref}>
              {title}
            </h2>
          )}
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
        {hasProgressBar && (
          <DynamicProgressBar
            step={1}
            topText="за заполнение контактной информации"
            percentToGet={20}
            initProgress={0}
            percentsForField={PERCENTS_FOR_FIELDS}
          />
        )}
        <FormLanding
          classes={{
            container: styles.position,
          }}
          name={name}
          onChangeFields={onChangeFields}
          onSubmit={handleSubmit}
          onClick={() => handleClickForm}
          ref={refForm}
        >
          {((hasESIA && isESIAShow) || utmTags) && (
            <ESIALoginComponent disabled={esiaDisabledButton} onClick={handleESIAClick} />
          )}
          <FormControl>
            <DadataFio
              data-testid="fio"
              label={fieldsLabel.fio}
              name="fio"
              incomingValue={fioDefaultValue}
            />
          </FormControl>
          <FormControl>
            <DateField
              data-testid="birthDate"
              label={fieldsLabel.birthDate}
              name="birthDate"
              placeholder="дд.мм.гггг"
              max={new Date()}
              validAgeMin={18}
              validAgeMax={85}
              defaultValue={birthDate}
              key={birthDate}
            />
          </FormControl>
          <FormControl>
            <PhoneFieldWithSeven
              data-testid="phone"
              label={fieldsLabel.phone}
              name="phone"
              key={phone}
              defaultValue={phone}
            />
          </FormControl>
          {displayedFields.region && (
            <FormControl>
              <AutocompleteRegionByPhoneField
                data-testid="region"
                label={fieldsLabel.region}
                name="region"
                // только из-за halva-planetacard
                phone={regionOptions ? null : userPhone}
                // только для halva-planetacard
                options={regionOptions}
                onInputChange={(e: React.SyntheticEvent) => onInputChange(e, "region")}
              />
            </FormControl>
          )}
          {displayedFields.localityAddress && (
            <HiddenField name="localityAddress" hide={region.value}>
              <FormControl>
                <DadataAddress
                  data-testid="localityAddress"
                  label={fieldsLabel.localityAddress}
                  name="localityAddress"
                  dadataOptions={dadataOptions}
                  validate={validateDadataAddressWithoutHouse}
                />
              </FormControl>
            </HiddenField>
          )}
          {displayedFields.email && (
            <FormControl>
              <EmailField
                label="Email"
                name="email"
                helperText="Необязательно"
                validate={validateEmail}
                placeholder="example@example.ru"
              />
            </FormControl>
          )}
          {utmTags && (
            <FormControl>
              <TextField label="РБС" name="promocode" />
            </FormControl>
          )}
          {radioButtons && (
            <FormControl className={styles.customGroupField}>
              <RadioGroupSpecial
                items={items}
                name="inviteFriendFromWhom"
                color="primary"
                label={
                  <div className={styles.labelText}>
                    Откуда вы узнали про акцию &quot;Приведи друга&quot;
                  </div>
                }
              />
            </FormControl>
          )}
          <AcceptmentField name="agree" />
          <div className="formBtnOuter">
            <SubmitButton data-testid="submit" className="formBtn" disabled={isSubmitting}>
              {submitButtonText}
            </SubmitButton>
          </div>
        </FormLanding>
        {checkUrl() && (
          <div className="formBtnOuter">
            <Button className="formBtn" onClick={fillNewForm}>
              Заполнить другую заявку
            </Button>
          </div>
        )}
      </Container>
    </section>
  )
}
